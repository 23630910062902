import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

function NumberTranslate({ value }) {
  const { i18n } = useTranslation('lang');

  const numericValue = Number(value);
  if (Number.isNaN(numericValue)) return '–';

  return numericValue.toLocaleString(
    i18n.language === 'en' ? 'en-US' : 'de-DE',
    {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }
  );
}

NumberTranslate.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
};

export default NumberTranslate;
