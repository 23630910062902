import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import styles from './index.module.scss';
import { ReactComponent as TrashSvg } from '../../assets/svgs/trash.svg';
import NumberControl from '../form-fields/number-control';
import NumberTranslate from '../number-translate';
// import Translate from '../translate';

function AmountInput({
  quantity,
  onChangeQuantity,
  rateExclusiveVat,
  rateInclusiveVat,
  onChangePrice,
  readOnly,
  productUnit,
  inclusiveOnly,
  strict,
  alreadySelected,
}) {
  const { t, i18n } = useTranslation('lang');
  const [price, setPrice] = useState(rateInclusiveVat);
  const priceChange = (number) => {
    setPrice(number);
    if (i18n.language !== 'en') {
      onChangePrice(number ? +number.toString().replace(',', '.') : 0);
    } else {
      onChangePrice(+number || 0);
    }
  };

  return (
    <div className={`d-sm-flex align-items-sm-center  ${styles.numberFieldFlex}`}>
      <div className={`d-flex align-items-center justify-content-between ${styles.firstColumn}`}>
        <div>{t('offer.products.amount')}</div>
        <div>
          {!inclusiveOnly && (
            <NumberControl
              name="amount_quantity"
              unit={productUnit}
              value={quantity}
              onChange={onChangeQuantity}
              disable={readOnly || alreadySelected}
              isControlRequired={!readOnly && !alreadySelected}
            />
          )}
        </div>
      </div>

      {!strict && (
        <div className={`d-flex align-items-center justify-content-between ${styles.secondColumn}`}>
          <div className={`d-flex align-items-center  ${styles.innerColumnContainer}`}>
            {inclusiveOnly ? (
              <div>
                {quantity} {productUnit}
              </div>
            ) : (
              <>
                <div>
                  <NumberTranslate value={rateExclusiveVat} />
                </div>
                <div>{t('units.euro')}</div>
              </>
            )}
          </div>

          <div>
            <NumberControl
              name="amount_price"
              unit={`${t('units.euro')}/${productUnit}`}
              value={price}
              onChange={priceChange}
              isPrice
              disable={readOnly}
              isControlRequired={!readOnly}
            />
          </div>
        </div>
      )}
    </div>
  );
}

function PurchasePrice({ purchasePriceInclusiveVat, purchasePriceExclusiveVat, inclusiveOnly }) {
  const { t, i18n } = useTranslation('lang');
  return (
    <div className={`d-sm-flex align-items-sm-center ${styles.priceFieldFlex}`}>
      <div>{t('offer.products.purchase_price')}</div>
      <div className="d-flex align-items-center justify-content-between">
        {!inclusiveOnly ? (
          <div className="d-flex align-items-center">
            <div className="text-break">
              <NumberTranslate value={purchasePriceExclusiveVat} />
            </div>
            <div>{t('units.euro')}</div>
          </div>
        ) : (
          <div />
        )}
        <div className="d-flex align-items-center">
          <div>
            <input
              className={`inputField ${styles.inputField}`}
              type="text"
              name="selling_price"
              value={Number(purchasePriceInclusiveVat).toLocaleString(
                i18n.language === 'en' ? 'en' : 'DE'
              )}
              disabled
            />
          </div>
          <div>{t('units.euro')}</div>
        </div>
      </div>
    </div>
  );
}

function SellingPriceInput({ sellingPriceInclusiveVat, sellingPriceExclusiveVat, inclusiveOnly }) {
  const { t, i18n } = useTranslation('lang');
  return (
    <div className={`d-sm-flex align-items-sm-center ${styles.priceFieldFlex}`}>
      <div>
        {inclusiveOnly
          ? t('offer.products.selling_price_incl_vat')
          : t('offer.products.selling_price')}
      </div>
      <div className="d-flex align-items-center justify-content-between">
        {!inclusiveOnly ? (
          <div className="d-flex align-items-center">
            <div>
              <NumberTranslate value={sellingPriceExclusiveVat} />
            </div>
            <div>{t('units.euro')}</div>
          </div>
        ) : (
          <div />
        )}
        <div className="d-flex align-items-center">
          <div>
            <input
              className={`inputField ${styles.inputField}`}
              type="text"
              name="selling_price"
              value={new Intl.NumberFormat(i18n.language === 'en' ? 'en-US' : 'fi-FI', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              }).format(sellingPriceInclusiveVat)}
              disabled
            />
          </div>
          <div>{t('units.euro')}</div>
        </div>
      </div>

      {/* <span>
        <Translate id="offer.products.unit_m" />
      </span> */}
    </div>
  );
}

export function RemoveButton({ onClick }) {
  const { t } = useTranslation('lang');
  return (
    <button className={`projectBtn ${styles.removeBtn}`} type="button" onClick={onClick}>
      <TrashSvg className={`${styles.removeBtnIcon}`} /> {t('components.button.remove')}
    </button>
  );
}

function InclusiveExclusiveLabels({ vat }) {
  const { t } = useTranslation('lang');

  return (
    <div className={`d-sm-flex align-items-center justify-content-between ${styles.vatContainer}`}>
      <div>{/* <p>blank div</p> */}</div>
      <div className="d-flex align-items-center justify-content-between">
        <div>{t('offer.products.exclusive_vat')}</div>
        <div>{t('offer.products.inclusive_vat', { vatPercentage: vat })}</div>
      </div>
    </div>
  );
}

function ProductPriceFields({
  id,
  name,
  amount,
  purchasePriceInclusiveVat,
  purchasePriceExclusiveVat,
  rateInclusiveVat,
  rateExclusiveVat,
  onRemove,
  sellingPriceInclusiveVat,
  sellingPriceExclusiveVat,
  onChangeProductQuantity,
  onChangeProductPrice,
  productUnit,
  readOnly,
  vat,
  inclusiveOnly,
  alreadySelected,
  strict,
}) {
  return (
    <div className={`${styles.productFieldContainer}`}>
      <div className={`d-flex align-items-center ${styles.headerContainer}`}>
        <div>
          <p>
            ID: {id} - <span className={`${styles.headerName}`}>{name}</span>
          </p>
        </div>
        <div>{!readOnly && !alreadySelected && <RemoveButton onClick={onRemove} />}</div>
      </div>
      <div className={`${styles.contentContainer}`}>
        {!strict && !inclusiveOnly ? <InclusiveExclusiveLabels vat={vat} /> : null}
        <AmountInput
          quantity={amount}
          rateInclusiveVat={rateInclusiveVat}
          rateExclusiveVat={rateExclusiveVat}
          onChangeQuantity={(value) => onChangeProductQuantity(id, value < 0 ? 0 : value)}
          onChangePrice={(value) => onChangeProductPrice(id, value < 0 ? 0 : value)}
          readOnly={readOnly}
          productUnit={productUnit}
          inclusiveOnly={inclusiveOnly}
          strict={strict}
          alreadySelected={alreadySelected}
        />
        {!strict && (
          <>
            {!inclusiveOnly ? <InclusiveExclusiveLabels vat={vat} /> : null}
            <PurchasePrice
              purchasePriceInclusiveVat={purchasePriceInclusiveVat}
              purchasePriceExclusiveVat={purchasePriceExclusiveVat}
              inclusiveOnly={inclusiveOnly}
            />
            <SellingPriceInput
              sellingPriceInclusiveVat={sellingPriceInclusiveVat}
              sellingPriceExclusiveVat={sellingPriceExclusiveVat}
              inclusiveOnly={inclusiveOnly}
            />
          </>
        )}
      </div>
    </div>
  );
}

ProductPriceFields.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  amount: PropTypes.number.isRequired,
  rateExclusiveVat: PropTypes.number.isRequired,
  rateInclusiveVat: PropTypes.number.isRequired,
  purchasePriceInclusiveVat: PropTypes.number.isRequired,
  purchasePriceExclusiveVat: PropTypes.number.isRequired,
  onRemove: PropTypes.func.isRequired,
  sellingPriceInclusiveVat: PropTypes.number.isRequired,
  sellingPriceExclusiveVat: PropTypes.number.isRequired,
  onChangeProductQuantity: PropTypes.func.isRequired,
  onChangeProductPrice: PropTypes.func.isRequired,
  productUnit: PropTypes.string.isRequired,
  readOnly: PropTypes.bool,
  vat: PropTypes.number.isRequired,
  inclusiveOnly: PropTypes.bool,
  alreadySelected: PropTypes.bool,
  strict: PropTypes.bool,
};

ProductPriceFields.defaultProps = {
  readOnly: false,
  inclusiveOnly: false,
  alreadySelected: false,
  strict: false,
};

AmountInput.propTypes = {
  quantity: PropTypes.number.isRequired,
  rateExclusiveVat: PropTypes.number.isRequired,
  rateInclusiveVat: PropTypes.number.isRequired,
  onChangeQuantity: PropTypes.func.isRequired,
  onChangePrice: PropTypes.func.isRequired,
  readOnly: PropTypes.bool.isRequired,
  productUnit: PropTypes.string.isRequired,
  inclusiveOnly: PropTypes.bool.isRequired,
  strict: PropTypes.bool.isRequired,
  alreadySelected: PropTypes.bool,
};

AmountInput.defaultProps = {
  alreadySelected: false,
};

PurchasePrice.propTypes = {
  purchasePriceInclusiveVat: PropTypes.number.isRequired,
  purchasePriceExclusiveVat: PropTypes.number.isRequired,
  inclusiveOnly: PropTypes.bool.isRequired,
};
SellingPriceInput.propTypes = {
  sellingPriceInclusiveVat: PropTypes.number.isRequired,
  sellingPriceExclusiveVat: PropTypes.number.isRequired,
  inclusiveOnly: PropTypes.bool.isRequired,
};

RemoveButton.propTypes = {
  onClick: PropTypes.func.isRequired,
};

InclusiveExclusiveLabels.propTypes = {
  vat: PropTypes.number.isRequired,
};

export default ProductPriceFields;
