import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'sonner';

import styles from './index.module.scss';
import Button from '../../../components/button';
import OfferPageContentWrapper from '../../../components/offer-page-content-wrapper';
import ProfitCalculation from '../../../components/profit-calculation';
import ServicesList from '../../../components/service-list';
import ServicePriceDetailRow from '../../../components/service-price-detail-row';
import Translate from '../../../components/translate';
import { attachmentableTypes } from '../../../constant';
import { paginationDataHandler } from '../../../services/api-client';
import { uploadAttachments } from '../../../services/attachment';
import heatingDeviceServices from '../../../services/heatingDevices';
import { getProductsCalculationsByOffer } from '../../../services/offerProducts';
import { getOfferServices, updateOfferServices } from '../../../services/offerService';
import { getServiceDetails, getServices } from '../../../services/service';
import { convertBase64ToFile } from '../../../utils/common';
import { getOriginalErrors } from '../../../utils/errorHandler';
import WithEnableDisableTab from '../withEnableDisableTab';

function ServicesFooter({ onSave, isChanged, onContinue }) {
  const { offerRequestId, offerId } = useParams();
  const navigate = useNavigate();
  const onBack = () => navigate(`/offer-requests/${offerRequestId}/offers/${offerId}/products`);
  return (
    <div className={`d-flex align-items-center justify-content-end ${styles.btnContainer}`}>
      <div>
        <Button className="projectBtn offerTabFooterBtn offerTabGrayBtn" onClick={onBack}>
          <Translate id="components.button.back" />
        </Button>
      </div>
      <div>
        {isChanged ? (
          <Button className="projectBtn offerTabFooterBtn offerTabGreenBtn" onClick={onSave}>
            <Translate id="components.button.save" />
          </Button>
        ) : (
          <Button className="projectBtn offerTabFooterBtn offerTabGreenBtn" onClick={onContinue}>
            <Translate id="components.button.continue" />
          </Button>
        )}
      </div>
    </div>
  );
}

function Services() {
  const [services, setServices] = useState([]);
  const [selectedServices, setSelectedServices] = useState([]);
  const [selectedServicesComparison, setSelectedServicesComparison] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [countOfServiceToDisplay, setCountOfServiceToDisplay] = useState(0);
  const [pagination, setPagination] = useState({});
  const [totalCountOfService, setTotalCountOfService] = useState(0);
  const [productsAndHeatingDevicesPrices, setProductsAndHeatingDevicesPrices] = useState({
    totalPurchasePrice: 0,
    totalSellingPrice: 0,
  });

  const { t } = useTranslation('lang');

  const params = useParams();
  const navigate = useNavigate();

  const onServiceRemove = (removableService, index) => {
    setServices((prevServices) =>
      prevServices.map((s) => ({
        ...s,
        selected:
          s.netvisorId === removableService.netvisorId
            ? selectedServices.filter(
                (selectedService) => selectedService.netvisorId === s.netvisorId
              ).length > 1
            : s.selected,
      }))
    );

    setSelectedServices((prevServices) => {
      const newServices = [...prevServices];
      newServices.splice(index, 1);
      return newServices;
    });
  };

  const setService = (service, index) => {
    setSelectedServices((prevServices) => {
      const updatedServices = [...prevServices];
      updatedServices[index] = service;
      return updatedServices;
    });
  };

  const fetchSelectedServices = async () => {
    const {
      data: {
        data: { offerServices },
      },
    } = await getOfferServices(params.offerRequestId, params.offerId);
    setSelectedServices(offerServices);
    setSelectedServicesComparison(offerServices);
  };

  const fetchServices = async (q) => {
    try {
      const {
        data: {
          data: { services: netvisorServices },
          pagination: paginationData,
          meta: { totalItems },
        },
      } = await getServices({ q });
      setServices(
        netvisorServices.map((service) => ({
          ...service,
          selected: selectedServices.findIndex((s) => s.netvisorId === service.netvisorId) !== -1,
        }))
      );
      setPagination(paginationData);
      setCountOfServiceToDisplay(netvisorServices.length);
      setTotalCountOfService(+totalItems);
    } catch (error) {
      setServices([]);
      setCountOfServiceToDisplay(0);
      setTotalCountOfService(0);
    }
  };

  const showMoreOrLessServices = async () => {
    if (countOfServiceToDisplay === totalCountOfService) {
      const path = pagination.first.split('/api')[1];
      const {
        data: {
          data: { services: netvisorServices },
          pagination: paginationData,
          meta: { totalItems },
        },
      } = await paginationDataHandler(path);
      setServices(
        netvisorServices.map((service) => ({
          ...service,
          selected: selectedServices.findIndex((p) => p.netvisorId === service.netvisorId) !== -1,
        }))
      );
      setPagination(paginationData);
      setCountOfServiceToDisplay(netvisorServices.length);
      setTotalCountOfService(+totalItems);
      return;
    }

    if (pagination.next) {
      const path = pagination.next.split('/api')[1];
      const {
        data: {
          data: { services: netvisorServices },
          pagination: paginationData,
        },
      } = await paginationDataHandler(path);
      setServices((prev) => [
        ...prev,
        ...netvisorServices.map((service) => ({
          ...service,
          selected: selectedServices.findIndex((p) => p.netvisorId === service.netvisorId) !== -1,
        })),
      ]);
      setPagination(paginationData);
      setCountOfServiceToDisplay((prev) => prev + netvisorServices.length);
    }
  };

  const saveService = async (allSelectedServices) => {
    const servicesToAdd = allSelectedServices.map((service) => {
      const { attachments, ...rest } = service;
      return rest;
    });

    const {
      data: {
        data: { offerServices: updatedServices },
      },
    } = await updateOfferServices(params.offerRequestId, params.offerId, servicesToAdd);

    updatedServices.map(async (updatedService) => {
      const service = allSelectedServices.find((d) => d.netvisorId === updatedService.netvisorId);
      if (service) {
        const { attachments } = service;
        if (Array.isArray(attachments) && attachments.length > 0) {
          const serviceAttachments = [];

          attachments.forEach((attachment) => {
            const attachmentFile = convertBase64ToFile(
              attachment.$t,
              attachment.name,
              false,
              attachment.mimeType
            );

            serviceAttachments.push(attachmentFile);
          });

          await uploadAttachments(
            attachmentableTypes.SERVICE,
            updatedService.id,
            serviceAttachments
          );
        }
      }
    });
  };

  const onSave = async () => {
    try {
      if (selectedServices.length === 0) {
        await updateOfferServices(params.offerRequestId, params.offerId, []);
      } else {
        await saveService(selectedServices);
      }
      toast.success(t('offer.services.services_updated_successfully'));
      navigate(`/offer-requests/${params.offerRequestId}/offers/${params.offerId}/calculations`);
    } catch (err) {
      const originalErrors = getOriginalErrors(err);
      originalErrors.forEach((error) => {
        toast.error(error.message);
      });
    }
  };

  const onContinue = () => {
    navigate(`/offer-requests/${params.offerRequestId}/offers/${params.offerId}/calculations`);
  };
  const customFooter = () => (
    <ServicesFooter
      onSave={onSave}
      isChanged={JSON.stringify(selectedServices) !== JSON.stringify(selectedServicesComparison)}
      onContinue={onContinue}
    />
  );

  const getProductsAndHeatingDevicesCalculations = async () => {
    try {
      let totalPurchasePrice = 0;
      let totalSellingPrice = 0;
      const {
        data: { data: productsCalculationsData },
      } = await getProductsCalculationsByOffer(params.offerRequestId, params.offerId);
      if (productsCalculationsData) {
        totalPurchasePrice += productsCalculationsData.totalPurchasePrice;
        totalSellingPrice += productsCalculationsData.totalSellingPrice;
      }

      const {
        data: { data: heatingDevicesCalculations },
      } = await heatingDeviceServices.getOfferHeatingDevicesCalculations({
        requestId: params.offerRequestId,
        offerId: params.offerId,
      });

      if (heatingDevicesCalculations) {
        totalPurchasePrice += heatingDevicesCalculations.totalPurchasePrice;
        totalSellingPrice += heatingDevicesCalculations.totalSellingPrice;
      }

      setProductsAndHeatingDevicesPrices({ totalPurchasePrice, totalSellingPrice });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getProductsAndHeatingDevicesCalculations();
    fetchSelectedServices();
  }, []);

  useEffect(() => {
    if (searchTerm) {
      fetchServices(searchTerm);
    } else {
      setServices([]);
      setCountOfServiceToDisplay(0);
      setTotalCountOfService(0);
    }
  }, [searchTerm]);

  const getStringValue = (value) => {
    if (value) return value instanceof Object ? '' : value;
    return '';
  };

  const stringToBool = (value) => value === 'true';

  const getBooleanValue = (value) => {
    if (value) return value instanceof Object ? false : stringToBool(value);
    return false;
  };

  const onServiceSelect = async (service) => {
    if(!(service.provider)){
      toast.error(t('offer.services.service_provider_error',{serviceName:service.name}));
      return;
    }

    if(!(service.provider.email)){
      toast.error(t('offer.services.service_provider_email_error',{serviceName:service.name}));
      return;
    }

    if(!(service.provider.name)){
      toast.error(t('offer.services.service_provider_name_error',{serviceName:service.name}));
      return;
    }

    setServices((prevServices) =>
      prevServices.map((s) => ({
        ...s,
        selected: s.netvisorId === service.netvisorId || s.selected,
      }))
    );
    const serviceToAdd = { ...service };

    delete serviceToAdd.id;

    const { netvisorImageId, netvisorAttachmentIds } = serviceToAdd;
    const queryParams = {};
    if (netvisorImageId && netvisorImageId != null) {
      queryParams.netvisorImageId = netvisorImageId;
    }

    if (netvisorAttachmentIds && netvisorAttachmentIds.length > 0) {
      queryParams.netvisorAttachmentIds = netvisorAttachmentIds.join(',');
    }

    let type = getStringValue(serviceToAdd.category);
    if (type === '') type = null;
    let companyName = '';
    let companyAdminEmail = '';
    let companyAddressStreet = '';
    let companyAddressCity = '';
    let companyAddressZipCode = '';
    let companyAdminPhoneNumber = '';
    if (serviceToAdd.provider) {
      companyName = getStringValue(serviceToAdd.provider.name);
      companyAdminEmail = getStringValue(serviceToAdd.provider.email);
      companyAddressStreet = getStringValue(serviceToAdd.provider.addressStreet);
      companyAddressCity = getStringValue(serviceToAdd.provider.addressCity);
      companyAddressZipCode = getStringValue(serviceToAdd.provider.addressZipCode);
      companyAdminPhoneNumber = getStringValue(serviceToAdd.provider.phoneNumber);
    }
    let insideConnectionRequired = false;
    let removalOfDrillingWasteRequired = false;
    if (serviceToAdd.extras) {
      insideConnectionRequired = getBooleanValue(serviceToAdd.extras.hasConnectionRequirement);
      removalOfDrillingWasteRequired = getBooleanValue(serviceToAdd.extras.hasDrillingWasteRemoval);
    }

    delete serviceToAdd.extras;
    delete serviceToAdd.provider;
    delete serviceToAdd.category;

    const {
      data: {
        data: { service: selectedServiceData },
      },
    } = await getServiceDetails(serviceToAdd.netvisorId, queryParams);

    const sellingPrice = +selectedServiceData.sellingPrice.replace('.', '').replace(',', '.');
    const purchasePrice = +selectedServiceData.purchasePrice.replace('.', '').replace(',', '.');
    const vat = parseFloat(selectedServiceData.vat.toString().replace(',', '.'));
    const { attachments } = selectedServiceData;

    setSelectedServices((prevServices) => [
      ...prevServices,
      {
        ...serviceToAdd,
        purchasePrice,
        sellingPrice,
        sellingPriceExclusiveVat: sellingPrice,
        sellingPriceInclusiveVat: Number(Number((sellingPrice * (vat + 100)) / 100).toFixed(2)),
        purchasePriceInclusiveVat: Number(Number((purchasePrice * (vat + 100)) / 100).toFixed(2)),
        purchasePriceExclusiveVat: purchasePrice,
        quantity: 1,
        workshare: 0,
        vat,
        attachments,
        type,
        insideConnectionRequired,
        removalOfDrillingWasteRequired,
        companyName,
        companyAdminEmail,
        companyAddressStreet,
        companyAddressCity,
        companyAddressZipCode,
        companyAdminPhoneNumber,
        unit: selectedServiceData.unit,
        rateExclusiveVat: sellingPrice,
        rateInclusiveVat: Number(Number((sellingPrice * (vat + 100)) / 100).toFixed(2)),
      },
    ]);
  };

  return (
    <OfferPageContentWrapper customFooter={customFooter}>
      <div className={`d-flex flex-column ${styles.serviceListContainer}`}>
        <ServicesList
          services={services}
          setKeyword={setSearchTerm}
          keyword={searchTerm}
          onServiceClick={onServiceSelect}
          countOfServiceToDisplay={countOfServiceToDisplay}
          setCountOfServiceToDisplay={setCountOfServiceToDisplay}
          showMoreOrLessServices={showMoreOrLessServices}
          totalCountOfService={totalCountOfService}
        />
        <div>
          <h1 className={`${styles.serviceListTitle}`}>
            <Translate id="offer.services.selected_services" />
          </h1>
          {selectedServices.length > 0 &&
            selectedServices.map((service, index) => (
              <ServicePriceDetailRow
                key={service.id}
                service={service}
                onServiceRemove={onServiceRemove}
                setService={setService}
                index={index}
              />
            ))}
        </div>
        <div className={`${styles.profitCalculation}`}>
          <ProfitCalculation
            items={selectedServices}
            purchasePriceFieldName="purchasePriceExclusiveVat"
            sellingPriceFieldName="sellingPriceExclusiveVat"
            previousPurchasePrice={productsAndHeatingDevicesPrices.totalPurchasePrice}
            previousSellingPrice={productsAndHeatingDevicesPrices.totalSellingPrice}
          />
        </div>
      </div>
    </OfferPageContentWrapper>
  );
}
ServicesFooter.propTypes = {
  onSave: PropTypes.func.isRequired,
};
ProfitCalculation.propTypes = {
  items: PropTypes.instanceOf(Array).isRequired,
};

export default WithEnableDisableTab(Services);
