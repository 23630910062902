import { Document, Image, PDFDownloadLink, PDFViewer, Page, Text, View } from '@react-pdf/renderer';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { toast } from 'sonner';

import styles from './finalOfferPreview.module.scss';
import FooterLogo from '../../../assets/images/footerLogo.png';
import OfferPreviewFooterLogo2 from '../../../assets/images/offer-preview-footer-logo-2.png';
import OfferPreviewFooterLogo3 from '../../../assets/images/offer-preview-footer-logo-3.png';
import { ReactComponent as PenSvg } from '../../../assets/svgs/Pen.svg';
import AttachmentList from '../../../components/attachment-list';
import Button from '../../../components/button';
import NumberTranslate from '../../../components/number-translate';
import OfferSignature from '../../../components/offer-signature';
import SignatureModal from '../../../components/signature-modal';
import Translate from '../../../components/translate';
import { offerRequestStatuses, userRoles } from '../../../constant';
import { downloadAttachment } from '../../../services/attachment';
import { getCurrentSessionUser } from '../../../services/authorization';
import { declineOffer } from '../../../services/offer';
import { getOfferPreview } from '../../../services/offer-preview';
import {
  getCustomerSignature,
  saveCustomerSignature,
  saveSalespersonSignature,
} from '../../../services/signature';
import { downloadFile } from '../../../utils/common';
import { getOriginalErrors } from '../../../utils/errorHandler';

function getBulletItems(description) {
  if (!description) {
    return null;
  }
  const lines = description.split('\n');
  const content = [];
  lines.forEach((line) => {
    if (line.trim().startsWith('-')) {
      content.push(line.replace('-', ''));
    } else {
      content.push(line.replace('-', ''));
    }
  });
  return content;
}

function TitledContent({ title, content }) {
  return (
    <>
      <View
        style={{
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'nowrap',
          color: '#707070',
        }}
      >
        <Text
          style={{
            flexBasis: '20%',
            fontWeight: '600',
            fontSize: '12px',
          }}
        >
          {title}
        </Text>
        <Text
          style={{
            flexBasis: '80%',
            fontSize: '12px',
            fontWeight: '400',
          }}
        >
          {/* {content} */}
        </Text>
      </View>
      <View
        style={{
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'nowrap',
          color: '#707070',
        }}
      >
        <Text
          style={{
            flexBasis: '20%',
            fontWeight: '600',
            fontSize: '12px',
          }}
        >
          {/* {title} */}
        </Text>
        <Text
          style={{
            flexBasis: '80%',
            fontSize: '12px',
            fontWeight: '400',
          }}
        >
          {content}
        </Text>
      </View>
    </>
  );
}

function DeviceInformation({ device }) {
  return (
    <View style={{ display: 'flex', flexDirection: 'row', flexWrap: 'nowrap' }}>
      <div style={{ flexBasis: '20%' }} />
      <div style={{ flexBasis: '80%' }}>
        {device.brandImage && (
          <div
            style={{
              width: '35%',
              marginLeft: '13%',
              marginTop: '16px',
            }}
          >
            <Image src={device.brandImage} alt="brand-img" />
          </div>
        )}
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'nowrap',
            color: '#707070',
            marginTop: '16px',
          }}
        >
          <div style={device?.image ? { flexBasis: '60%' } : { flexBasis: '100%' }}>
            <div
              style={{
                fontSize: '14px',
                fontWeight: '900',
                color: '#707070',
                marginBottom: '16px',
              }}
            >
            <Text key={device.id}>
                {device.name} ({device.amount ? `${device.amount} ${device.unit}` : `${device.quantity} ${device.unit}`})
            </Text>
            </div>
            <div
              style={{
                fontSize: '12px',
                fontWeight: '400',
                color: '#707070',
              }}
            >
              {getBulletItems(device.description)?.map((desc) => (
                <View
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    paddingTop: '2.5px',
                    paddingBottom: '2.5px',
                  }}
                  key={desc}
                >
                  <Text style={{ marginRight: '7px' }}>&#8226;</Text>
                  <Text>{desc}</Text>
                </View>
              ))}
            </div>
          </div>
          {device.image && (
            <div
              style={{
                flexBasis: '40%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Image
                style={{
                  width: '100%',
                  maxHeight: '370px',
                  display: 'block',
                  objectFit: 'contain',
                }}
                src={device.image}
                alt="device-img"
              />
            </div>
          )}
        </div>
      </div>
    </View>
  );
}

function PriceInformation({ calculations, offer }) {
  const { t } = useTranslation('lang');
  const [calculationsToShow, setCalculationsToShow] = useState([]);

  useState(() => {
    if (calculations) {
      const tempCalculations = [];
      Object.keys(calculations.services).forEach((key) => {
        tempCalculations.push({
          name: key.substring(
            0,
            key.lastIndexOf('_-s-_') > 0 ? key.lastIndexOf('_-s-_') : key.length
          ),
          ...calculations.services[key],
        });
      });
      setCalculationsToShow(tempCalculations);
    }
  }, [calculations]);

  const calculationsView = calculationsToShow.map((calculation) => (
    <View
      style={{
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'nowrap',
        color: '#707070',
        fontSize: '12px',
        fontWeight: '500',
        padding: '8px',
        borderTop: '1px solid #c5c5c5',
      }}
    >
      <Text
        style={{
          flexBasis: '25%',
          borderRight: '1px solid #c5c5c5',
          paddingRight: '8px',
        }}
      >
        {['hardware', 'total'].includes(calculation.name.toLowerCase())
          ? t(`offer.calculations.${calculation.name.toLowerCase()}`)
          : calculation.name.charAt(0).toUpperCase() + calculation.name.slice(1)}
      </Text>
      <Text
        style={{
          flexBasis: '25%',
          paddingLeft: '8px',
          paddingRight: '8px',
        }}
      >
        <NumberTranslate value={calculation.accessory} /> {calculation.accessory ? '€' : ''}
      </Text>
      <Text
        style={{
          flexBasis: '25%',
          borderLeft: '1px solid #c5c5c5',
          paddingLeft: '8px',
          paddingRight: '8px',
        }}
      >
        <NumberTranslate value={calculation.workshare} /> {calculation.workshare ? '€' : ''}
      </Text>
      <Text
        style={{
          flexBasis: '25%',
          paddingLeft: '8px',
          borderLeft: '1px solid #c5c5c5',
        }}
      >
        <NumberTranslate value={calculation.total} /> {calculation.total ? '€' : ''}
      </Text>
    </View>
  ));

  return (
    <View style={{ width: '80%' }}>
      <div
        style={{
          textAlign: 'center',
          marginTop: '16px',
          marginBottom: '16px',
        }}
      >
        <Text
          style={{
            fontSize: '16px',
            fontWeight: '700',
            color: '#707070',
          }}
        >
          {t('offer.final_offer_preview.pdf.price_of_geothermal_heating_contract')}
        </Text>
        <Text
          style={{
            fontSize: '12px',
            fontWeight: '600',
            color: '#707070',
          }}
        >
          {calculations.services.total.total.toFixed(2)} € (incl. VAT 24%)
        </Text>
      </div>
      <div
        style={{
          backgroundColor: '#EFEFEF',
          borderRadius: '18px',
          marginBottom: '16px',
        }}
      >
        <View
          style={{
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'nowrap',
            color: '#707070',
            fontSize: '12px',
            fontWeight: '600',
            padding: '8px',
          }}
        >
          <Text
            style={{
              flexBasis: '25%',
            }}
          >
            {t('offer.calculations.services')}
          </Text>
          <Text
            style={{
              flexBasis: '25%',
              paddingLeft: '8px',
              borderLeft: '1px solid #c5c5c5',
            }}
          >
            {t('offer.calculations.accessory')}
          </Text>
          <Text
            style={{
              flexBasis: '25%',
              paddingLeft: '8px',
              borderLeft: '1px solid #c5c5c5',
            }}
          >
            {t('offer.calculations.work_share')}
          </Text>
          <Text
            style={{
              flexBasis: '25%',
              paddingLeft: '8px',
              borderLeft: '1px solid #c5c5c5',
            }}
          >
            {t('offer.calculations.total')}
          </Text>
        </View>

        {calculationsView}
        <View
          style={{
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'nowrap',
            color: '#707070',
            fontSize: '12px',
            fontWeight: '600',
            borderTop: '1px solid #c5c5c5 ',
          }}
        >
          <Text
            style={{
              marginTop: '10px',
              paddingLeft: '8px',
              marginBottom: '10px',
              flexBasis: '50%',
            }}
          >
            <Translate id="offer.calculations.reduction" /> {offer.householdDeductionRate}%,{' '}
            <Translate id="offer.calculations.max" />{' '}
            {offer.isUpgradeFromOilHeating ? (
              <NumberTranslate value={7000} />
            ) : (
              <NumberTranslate value={3200} />
            )}
            €/{'\u00A0'}2{'\u00A0'}
            <Translate id="offer.calculations.persons" />
          </Text>
          <Text
            style={{
              marginTop: '10px',
              paddingLeft: '8px',
              marginBottom: '10px',
              flexBasis: '50%',
              borderLeft: '1px solid #c5c5c5 ',
            }}
          >
            -<NumberTranslate value={calculations.reductionValue} />{' '}
            {calculations.reductionValue ? '€' : ''}
          </Text>
        </View>
        <View
          style={{
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'nowrap',
            color: '#707070',
            fontSize: '12px',
            fontWeight: '600',
            borderTop: '1px solid #c5c5c5 ',
          }}
        >
          <Text
            style={{
              marginTop: '10px',
              paddingLeft: '8px',
              marginBottom: '10px',
              flexBasis: '80%',
            }}
          >
            <Translate id="offer.calculations.invoiceable_total_after_deduction" />
          </Text>
          <Text
            style={{
              marginTop: '10px',
              paddingLeft: '8px',
              marginBottom: '10px',
              flexBasis: '25%',
              borderLeft: '1px solid #c5c5c5 ',
            }}
          >
            <NumberTranslate value={calculations.invoiceTotal} />{' '}
            {calculations.invoiceTotal ? '€' : ''}
          </Text>
        </View>
      </div>
    </View>
  );
}

function PDFPage({
  allDevices,
  heatingDevices,
  customer,
  employee,
  offerIntroduction,
  calculations,
  customerSignature,
  salesPersonSignature,
  offer,
}) {
  const { t } = useTranslation('lang');

  const devicesView = allDevices.map((device) => <DeviceInformation device={device} />);
  return (
    <Page
      style={{
        padding: '16px',
        position: 'relative',
        paddingBottom: '140px',
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'nowrap',
          alignItems: 'center',
          color: '#707070',
        }}
      >
        <div
          style={{
            flexBasis: '80%',
          }}
        >
          <View>
            <Text
              style={{
                color: '#707070',
                fontWeight: '600',
                fontSize: '12px',
              }}
            >
              Lännen Maalämpö Oy
            </Text>
            <Text
              style={{
                color: '#707070',
                fontWeight: '400',
                fontSize: '12px',
              }}
            >
              Puurtajantie 18
            </Text>
            <Text
              style={{
                color: '#707070',
                fontWeight: '400',
                fontSize: '12px',
              }}
            >
              60100 Seinäjoki
            </Text>
          </View>
        </div>
        <div
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            textAlign: 'center',
            color: '#707070',
            fontWeight: '500',
            fontSize: '12px',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Text style={{ paddingBottom: '12px' }}>{t('offer.final_offer_preview.pdf.offer')}</Text>
          <Text>
            {salesPersonSignature ? (
              <>{moment(salesPersonSignature.signedAt).format('DD.MM.YYYY')}</>
            ) : (
              <>{moment(new Date()).format('DD.MM.YYYY')}</>
            )}
          </Text>
        </div>
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'nowrap',
          alignItems: 'center',
          color: '#707070',
          marginTop: '24px',
        }}
      >
        <div
          style={{
            flexBasis: '80%',
          }}
        >
          <View>
            <Text
              style={{
                color: '#707070',
                fontWeight: '600',
                fontSize: '12px',
              }}
            >
              {`${customer.firstName} ${customer.lastName}`}
            </Text>
            <Text
              style={{
                color: '#707070',
                fontWeight: '400',
                fontSize: '12px',
              }}
            >
              {customer.address}
            </Text>
            <Text
              style={{
                color: '#707070',
                fontWeight: '400',
                fontSize: '12px',
              }}
            >
              {`${customer.zipCode} ${customer.city}`}
            </Text>
          </View>
        </div>
      </div>
      <div
        style={{
          marginTop: '16px',
          marginBottom: '16px',
        }}
      >
        <TitledContent title="" content={t('offer.final_offer_preview.pdf.greeting')} />
      </div>
      <div
        style={{
          marginBottom: '16px',
        }}
      >
        <TitledContent
          title={t('offer.final_offer_preview.pdf.contact')}
          content={`${
            customer
              ? `${customer.firstName} ${customer.lastName}, ${customer.phone}, ${customer.email}`
              : ''
          }`}
        />
      </div>
      <TitledContent
        title={t('offer.final_offer_preview.offer_text')}
        content={offerIntroduction}
      />

      <div>
        <View style={{ display: 'flex', flexDirection: 'row', flexWrap: 'nowrap' }}>
          <Text style={{ flexBasis: '20%', fontSize: '12px', color: '#707070' }}>
            {t('offer.final_offer_preview.pdf.products')}
          </Text>
          <Text style={{ flexBasis: '80%' }} />
        </View>
        {devicesView}
      </div>
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <PriceInformation
          calculations={calculations}
          heatingDevices={heatingDevices}
          offer={offer}
        />
      </div>
      {offer.heatingDevicesComment && (
        <div
          style={{
            marginBottom: '16px',
          }}
        >
          <TitledContent
            title={t('offer.final_offer_preview.heating_device_comment')}
            content={offer.heatingDevicesComment}
          />
        </div>
      )}

      {offer.servicesComment && (
        <div
          style={{
            marginBottom: '16px',
          }}
        >
          <TitledContent
            title={t('offer.final_offer_preview.services_comment')}
            content={offer.servicesComment}
          />
        </div>
      )}

      {offer.productsComment && (
        <div
          style={{
            marginBottom: '16px',
          }}
        >
          <TitledContent
            title={t('offer.final_offer_preview.products_comment')}
            content={offer.productsComment}
          />
        </div>
      )}
      <div
        style={{
          marginBottom: '16px',
        }}
      >
        <TitledContent title={t('offer.final_offer_preview.offer_validity')}
         content={t('offer.final_offer_preview.1 month')}/>
      </div>
      <div
        style={{
          marginBottom: '16px',
        }}
      >
        <TitledContent
          title={t('offer.final_offer_preview.invoicing')}
          content={t('offer.final_offer_preview.invoicing_message')}
        />
      </div>
      <div
        style={{
          marginBottom: '16px',
        }}
      >
        <TitledContent
          title={t('offer.final_offer_preview.other')}
          content="Lännen Maalämpö Oy on valtuutettu Thermia jälleenmyyjä ja huoltoliike sekä alueesi Boch Lämpömestari.

Panostamme asiakastyytyväisyyteen ja pidämme huolen, että saat juuri oikeanlaisen ratkaisun kotiisi ja asianmukaisen käytönopastuksen laitteillesi. "
        />
      </div>
      <div
        style={{
          marginBottom: '16px',
        }}
      >
        <TitledContent
          title={t('offer.final_offer_preview.more_information')}
          content={`${t('offer.final_offer_preview.moreInfo_content1')} ${
            employee ? `${employee.firstName} ${employee.lastName || ''}` : ''
          } - ${employee ? employee.phone : ''} ${t(
            'offer.final_offer_preview.moreInfo_content2'
          )}  ${employee ? employee.email : ''}`}
        />
      </div>
      <div
        style={{
          marginBottom: '16px',
        }}
      >
        <TitledContent title="" content={t('offer.final_offer_preview.moreInfo_content3')} />
      </div>
      <OfferSignature
        customerSignature={customerSignature}
        salesPersonSignature={salesPersonSignature}
        customerDetails={customer}
        salesPersonDetails={employee}
      />
      <View
        style={{
          position: 'absolute',
          left: '16px',
          right: '16px',
          bottom: '0',
          color: 'grey',
        }}
        fixed
      >
        <View
          style={{
            fontSize: 12,
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            width: '100%',
            justifyContent: 'space-between',
            paddingTop: '16px',
            paddingBottom: '16px',
          }}
        >
          <View
            style={{
              flexBasis: '40%',
            }}
          >
            <Text>Lännen Maalämpö Oy</Text>
            <Text>Seinäjoki – Tampere –</Text>
            <Text>Turku – Jyväskylä</Text>
            <Text>Y-Tunnus 2626239-1</Text>
          </View>
          <View
            style={{
              display: 'flex',
              alignItems: 'center',
              flexDirection: 'row',
              flexBasis: '60%',
            }}
          >
            <View style={{ flexBasis: '50%', paddingRight: '16px' }}>
              <View>
                <Text>
                  {employee ? `${employee.firstName} ${employee.lastName} - ${employee.phone}` : ''}
                </Text>
                <Text>www.lannenmaalampo.fi</Text>
              </View>
              <Text
                render={({ pageNumber }) =>
                  `${t('components.offer_preview_footer.page')} - ${pageNumber}`
                }
              />
            </View>
            <View style={{ flexBasis: '50%' }}>
              <div
                style={{
                  width: '100%',
                  marginBottom: '12px',
                }}
              >
                <Image
                  src={FooterLogo}
                  style={{
                    width: '70%',
                    height: 'auto',
                    display: 'block',
                  }}
                />
              </div>
              <View
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  flexBasis: '60%',
                  justifyContent: 'space-between',
                }}
              >
                <div
                  style={{
                    width: '48%',
                  }}
                >
                  <Image
                    src={OfferPreviewFooterLogo2}
                    style={{
                      width: '100%',
                      height: 'auto',
                      display: 'block',
                    }}
                  />
                </div>
                <div
                  style={{
                    width: '48%',
                  }}
                >
                  <Image
                    src={OfferPreviewFooterLogo3}
                    style={{
                      width: '100%',
                      height: 'auto',
                      display: 'block',
                    }}
                  />
                </div>
              </View>
            </View>
          </View>
        </View>
      </View>
    </Page>
  );
}

function OfferDoc({ offerPreview, customerSignature, salesPersonSignature }) {
  return (
    <Document>
      <PDFPage
        allDevices={
          offerPreview
            ? [
                ...offerPreview.heatingDevices,
                ...offerPreview.components,
                ...offerPreview.offerServices,
              ]
            : []
        }
        heatingDevices={offerPreview?.heatingDevices}
        customer={offerPreview?.customer}
        employee={offerPreview?.employee}
        offerIntroduction={offerPreview?.offer?.introduction}
        calculations={offerPreview?.calculations}
        customerSignature={customerSignature}
        salesPersonSignature={salesPersonSignature}
        offer={offerPreview?.offer}
      />
    </Document>
  );
}

function FinalOfferPreview({
  customerSignature,
  setCustomerSignature,
  offerPreview,
  setOfferPreview,
  offerRequestId,
  offerId,
  setLoading,
}) {
  const [userRole, setUserRole] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [userId, setUserId] = useState(null);

  const { t, i18n } = useTranslation('lang');
  const navigate = useNavigate();

  const fetchOfferPreview = async () => {
    setLoading(true);
    try {
      const {
        data: { data },
      } = await getOfferPreview(offerRequestId);
      setOfferPreview(data);
    } catch (errors) {
      if (errors[0]) {
        toast.error(errors[0].message);
        navigate(-1);
      }
    }
    setLoading(false);
  };

  const onDownloadClick = async ({ attachmentableType, attachmentableId, id, name }) => {
    try {
      const res = await downloadAttachment(attachmentableType, attachmentableId, id);
      downloadFile(res, name);
    } catch (err) {
      const originalErrors = getOriginalErrors(err);
      originalErrors.forEach((error) => {
        toast.error(error.message);
      });
    }
  };

  const fetchCustomerSignature = async () => {
    const {
      data: {
        data: { offerCustomerSignature },
      },
    } = await getCustomerSignature(offerRequestId, offerId);
    if (offerCustomerSignature) {
      setCustomerSignature(offerCustomerSignature);
    }
  };

  useEffect(() => {
    fetchOfferPreview();
  }, []);

  useEffect(() => {
    fetchCustomerSignature();
  }, [offerPreview]);

  useEffect(() => {
    if (userRole) {
      setUserId(
        userRole.role === userRoles.CUSTOMER.role
          ? offerPreview.offerRequest.customerId
          : getCurrentSessionUser().id
      );
      setShowModal(true);
    }
  }, [userRole]);

  const onCloseModal = (success) => {
    if (success) {
      fetchOfferPreview();
    }
    setUserRole(null);
    setShowModal(false);
  };

  const rejectOffer = async () => {
    try {
      await declineOffer(offerPreview.offerRequest.id, offerId);
      setOfferPreview({
        ...offerPreview,
        offerRequest: { ...offerPreview.offerRequest, status: 'DECLINED' },
      });
    } catch (err) {
      const originalErrors = getOriginalErrors(err);
      originalErrors.forEach((error) => {
        toast.error(error.message);
      });
    }
  };
  const setSignature = async (formData) => {
    setLoading(true);
    try {
      if (userRole.role === userRoles.CUSTOMER.role) {
        formData.append('customerId', userId);
        await saveCustomerSignature(offerRequestId, offerId, formData, i18n.language);
        setLoading(false);
      }
      if (userRole.role === userRoles.SALES_PERSON.role) {
        formData.append('userId', userId);
        await saveSalespersonSignature(offerRequestId, offerId, formData);
        setLoading(false);
      }
      toast.success(t('components.signature.success'));
    } catch (error) {
      toast.error(error.response.data.message);
      setLoading(false);
    }
  };

  return offerPreview ? (
    <div>
      <div className={`${styles.finalOfferPreviewTitle}`}>Offer Preview</div>
      <div className={`d-lg-flex ${styles.finalOfferPreviewContainer}`}>
        <div>
          {isMobile ? (
            <PDFDownloadLink
              document={
                <OfferDoc
                  offerPreview={offerPreview}
                  salesPersonSignature={offerPreview.salesPersonSignature}
                  customerSignature={customerSignature}
                />
              }
              fileName="offer.pdf"
            >
              {({ loading }) => (
                <div className={styles.downloadButtonContainer}>
                  {loading ? (
                    <>{t('offer.final_offer_preview.loading')}</>
                  ) : (
                    <Button className="projectBtn greenBtn">
                      {t('offer.final_offer_preview.download')}
                    </Button>
                  )}
                </div>
              )}
            </PDFDownloadLink>
          ) : (
            <PDFViewer showToolbar={false} className={`${styles.iframeContainer}`}>
              <OfferDoc
                offerPreview={offerPreview}
                salesPersonSignature={offerPreview.salesPersonSignature}
                customerSignature={customerSignature}
              />
            </PDFViewer>
          )}

          {/* {!instance.loading && <iframe src={instance.url} title="offer PDF" />} */}
          <div className={`${styles.signBtnMainContainer}`}>
            <div>{/*  */}</div>
            <div className={`${styles.signBtnContainer}`}>
              {!offerPreview.salesPersonSignature && (
                <div className={`${styles.btnContainer}`}>
                  <Button
                    className={`projectBtn ${styles.signBtn}`}
                    onClick={() => setUserRole(userRoles.SALES_PERSON)}
                  >
                    {t('components.signature.sign')} <PenSvg className={`${styles.signBtnIcon}`} />
                  </Button>
                </div>
              )}
              {!customerSignature &&
                offerPreview.offerRequest.status !== offerRequestStatuses.DECLINED.value && (
                  <div className={`${styles.btnContainer}`}>
                    <Button
                      className={`projectBtn ${styles.signBtn}`}
                      type="button"
                      onClick={() => setUserRole(userRoles.CUSTOMER)}
                    >
                      {t('components.signature.sign')}{' '}
                      <PenSvg className={`${styles.signBtnIcon}`} />
                    </Button>
                    <Button
                      type="button"
                      onClick={() => rejectOffer()}
                      className={`projectBtn ${styles.declineBtn}`}
                    >
                      {t('components.signature.decline_offer')}
                    </Button>
                  </div>
                )}
            </div>
          </div>
          <div>
            {showModal && (
              <SignatureModal
                userRole={userRole}
                onClose={onCloseModal}
                showModal={showModal}
                setSignature={setSignature}
              />
            )}
          </div>
        </div>

        <div>
          <AttachmentList
            attachments={[
              ...(offerPreview.technicalInformationAttachments
                ? [...offerPreview.technicalInformationAttachments]
                : []),
              ...(offerPreview.heatingDeviceAttachments
                ? offerPreview.heatingDeviceAttachments
                : []),
              ...(offerPreview.offerAttachments ? offerPreview.offerAttachments : []),
              ...(offerPreview.componentAttachments ? offerPreview.componentAttachments : []),
              ...(offerPreview.offerServiceAttachments ? offerPreview.offerServiceAttachments : []),
            ]}
            onDownloadClick={onDownloadClick}
          />
        </div>
      </div>
    </div>
  ) : null;
}

TitledContent.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
};

DeviceInformation.propTypes = {
  device: PropTypes.instanceOf(Object).isRequired,
};

PDFPage.propTypes = {
  allDevices: PropTypes.instanceOf(Array),
  heatingDevices: PropTypes.instanceOf(Array),
  customer: PropTypes.instanceOf(Object),
  employee: PropTypes.instanceOf(Object),
  offerIntroduction: PropTypes.string,
  calculations: PropTypes.instanceOf(Object),
};

PDFPage.defaultProps = {
  allDevices: [],
  heatingDevices: [],
  customer: null,
  employee: null,
  offerIntroduction: '',
  calculations: null,
};

PriceInformation.propTypes = {
  calculations: PropTypes.instanceOf(Array),
};

PriceInformation.defaultProps = {
  calculations: [],
};

OfferDoc.propTypes = {
  offerPreview: PropTypes.instanceOf(Object).isRequired,
  salesPersonSignature: PropTypes.instanceOf(Object).isRequired,
  customerSignature: PropTypes.instanceOf(Object).isRequired,
};

FinalOfferPreview.propTypes = {
  offerPreview: PropTypes.instanceOf(Object),
  setOfferPreview: PropTypes.func.isRequired,
  customerSignature: PropTypes.instanceOf(Object),
  setCustomerSignature: PropTypes.func.isRequired,
  offerRequestId: PropTypes.string.isRequired,
  offerId: PropTypes.string.isRequired,
  setLoading: PropTypes.func.isRequired,
};

FinalOfferPreview.defaultProps = {
  offerPreview: null,
  customerSignature: {},
};

export default FinalOfferPreview;
