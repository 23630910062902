import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'sonner';

import styles from './index.module.scss';
import Button from '../../../components/button';
import NumberControl from '../../../components/form-fields/number-control';
import HouseholdDeductionCalculations from '../../../components/household-deduction-calculation';
import OfferPageContentWrapper from '../../../components/offer-page-content-wrapper';
import Translate from '../../../components/translate';
import offerServices from '../../../services/offer';
import { getOriginalErrors } from '../../../utils/errorHandler';
import WithEnableDisableTab from '../withEnableDisableTab';

function HouseholdDeductionAmountInput({ onChange, value }) {
  return (
    <NumberControl
      label="offer.calculations.household_deduction_amount"
      name="household_deduction_amount"
      value={value}
      onChange={onChange}
      unit="%"
      className={`${styles.calculationControls}`}
    />
  );
}

function CalculationsFooter({ onSave, isChanged, onContinue }) {
  const { offerRequestId, offerId } = useParams();
  const navigate = useNavigate();
  const onBack = () => navigate(`/offer-requests/${offerRequestId}/offers/${offerId}/services`);
  return (
    <div className={`d-flex align-items-center justify-content-end ${styles.btnContainer}`}>
      <div>
        <Button className="projectBtn offerTabFooterBtn offerTabGrayBtn" onClick={onBack}>
          <Translate id="components.button.back" />
        </Button>
      </div>
      <div>
        {isChanged ? (
          <Button className="projectBtn offerTabFooterBtn offerTabGreenBtn" onClick={onSave}>
            <Translate id="components.button.save" />
          </Button>
        ) : (
          <Button className="projectBtn offerTabFooterBtn offerTabGreenBtn" onClick={onContinue}>
            <Translate id="components.button.continue" />
          </Button>
        )}
      </div>
    </div>
  );
}

function Calculations() {
  const [offer, setOffer] = useState({});
  const [calculations, setCalculations] = useState({
    services: {},
  });

  const [offerComparison, setOfferComparison] = useState({});

  const params = useParams();
  const navigate = useNavigate();

  const fetchOfferCalculations = async (householdDeductionRate, isUpgradeFromOilHeating) => {
    try {
      const {
        data: {
          data: { calculations: offerCalculations },
        },
      } = await offerServices.getOfferCalculations(
        params.offerRequestId,
        params.offerId,
        householdDeductionRate,
        isUpgradeFromOilHeating
      );
      setCalculations(offerCalculations);
    } catch (err) {
      const originalErrors = getOriginalErrors(err);
      originalErrors.forEach((error) => {
        toast.error(error.message);
      });
    }
  };

  const onChangeHouseholdDeductionRate = (householdDeductionRate) => {
    setOffer((prev) => ({ ...prev, householdDeductionRate }));
    fetchOfferCalculations(householdDeductionRate, offer.isUpgradeFromOilHeating);
  };

  const onChangeIsUpgradeFromOilHeating = (isUpgradeFromOilHeating) => {
    setOffer((prev) => ({
      ...prev,
      isUpgradeFromOilHeating,
      householdDeductionRate: isUpgradeFromOilHeating ? 60 : 35,
    }));
    fetchOfferCalculations(isUpgradeFromOilHeating ? 60 : 35, isUpgradeFromOilHeating);
  };

  const fetchOffer = async () => {
    try {
      const {
        data: {
          data: { offer: offerData },
        },
      } = await offerServices.getOfferById(params.offerRequestId, params.offerId);
      setOffer(offerData);
      setOfferComparison(offerData);
      fetchOfferCalculations(offerData.householdDeductionRate, offerData.isUpgradeFromOilHeating);
    } catch (err) {
      const originalErrors = getOriginalErrors(err);
      originalErrors.forEach((error) => {
        toast.error(error.message);
      });
    }
  };

  const onCalculationsSave = async () => {
    try {
      await offerServices.updateOfferById(params.offerRequestId, params.offerId, offer);
      fetchOffer();
      navigate(`/offer-requests/${params.offerRequestId}/offers/${params.offerId}/offer-paragraph`);
    } catch (err) {
      const originalErrors = getOriginalErrors(err);
      originalErrors.forEach((error) => {
        toast.error(error.message);
      });
    }
  };
  const onContinue = async () => {
    navigate(`/offer-requests/${params.offerRequestId}/offers/${params.offerId}/offer-paragraph`);
  };

  const customFooter = () => (
    <CalculationsFooter
      onSave={onCalculationsSave}
      isChanged={JSON.stringify(offer) !== JSON.stringify(offerComparison)}
      onContinue={onContinue}
    />
  );

  useEffect(() => {
    fetchOffer();
  }, []);

  return (
    <OfferPageContentWrapper customFooter={customFooter}>
      <div className={`${styles.calculationsContainer}`}>
        <p className={`${styles.calculationsTitle}`}>
          <Translate id="offer.calculations.title" />
        </p>
        <HouseholdDeductionAmountInput
          onChange={onChangeHouseholdDeductionRate}
          value={offer.householdDeductionRate}
        />
        <HouseholdDeductionCalculations
          calculations={calculations}
          isUpgradeFromOilHeating={offer.isUpgradeFromOilHeating}
          onChangeIsUpgradeFromOilHeating={onChangeIsUpgradeFromOilHeating}
          householdDeductionRate={offer.householdDeductionRate}
        />
      </div>
    </OfferPageContentWrapper>
  );
}

HouseholdDeductionAmountInput.propTypes = {
  value: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
};

CalculationsFooter.propTypes = {
  onSave: PropTypes.func.isRequired,
};

export default WithEnableDisableTab(Calculations);
