import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import styles from './index.module.scss';
import { translateNumber } from '../../utils/common';
import NumberTranslate from '../number-translate';
import Translate from '../translate';

function UpgradeFromOilHeatingInput({ ischecked, onChange }) {
  return (
    <div>
      <label htmlFor="upgrade_from_oil_heating" className="checkBoxContainer">
        <input
          type="checkbox"
          checked={ischecked}
          onChange={(e) => onChange(e.target.checked)}
          name="upgrade_from_oil_heating"
          id="upgrade_from_oil_heating"
        />
        <Translate id="offer.calculations.upgrade_from_oil_heating" />
        <span className="checkIcon" />
      </label>
    </div>
  );
}

function DesktopViewDeductionTable({
  calculations,
  householdDeductionRate,
  maxDeduction,
  personCount,
}) {
  const { t } = useTranslation('lang');
  return (
    <div className={`${styles.desktopCalculations}`}>
      <div className={`${styles.contentContainer}`}>
        <div className={`d-flex ${styles.flexContainer}`}>
          <div className={`${styles.flexTitle}`}>
            <Translate id="offer.calculations.services" />
          </div>
          <div className={`${styles.flexTitle}`}>
            <Translate id="offer.calculations.accessory" />
          </div>
          <div className={`${styles.flexTitle}`}>
            <Translate id="offer.calculations.work_share" />
          </div>
          <div className={`${styles.flexTitle}`}>
            <Translate id="offer.calculations.total" />
          </div>
        </div>
        {calculations.services.map(({ name, accessory, workshare, total }, index) => (
          <>
            <div className={`d-flex ${styles.flexContainer}`} key={name}>
              <div className={`${styles.flexData}`}>
                <Translate
                  id={
                    ['hardware', 'total'].includes(name.toLowerCase())
                      ? `offer.calculations.${name.toLowerCase()}`
                      : name.charAt(0).toUpperCase() + name.slice(1)
                  }
                />
              </div>
              <div className={`${styles.flexData}`}>
                <NumberTranslate value={accessory} /> {typeof accessory !== 'undefined' ? t('units.euro') : ''}
              </div>
              <div className={`${styles.flexData}`}>
                <NumberTranslate value={workshare} /> {typeof workshare !== 'undefined' ? t('units.euro') : ''}
              </div>
              <div className={`${styles.flexData}`}>
                <NumberTranslate value={total} /> {typeof total !== 'undefined' ? t('units.euro') : ''}
              </div>
            </div>
            {index + 2 === calculations.services.length && (
              <div className={`d-flex ${styles.flexContainer}`} key={`${name}_blank_line`} />
            )}
          </>
        ))}
      </div>
      <div className={`d-flex ${styles.translateContainer}`}>
        <div>
          {t('offer.calculations.reduction')} {householdDeductionRate}%,{' '}
          {t('offer.calculations.max')} {maxDeduction}
          {t('units.euro')}/ {personCount} {t('offer.calculations.persons')}
        </div>
        <div>
          -<NumberTranslate value={calculations.reductionValue} />{' '}
          {calculations.reductionValue ? t('units.euro') : ''}
        </div>
      </div>
      <div className={`d-flex ${styles.translateContainer} ${styles.invoiceTotalContainer}`}>
        <div>
          <Translate id="offer.calculations.invoiceable_total_after_deduction" />
        </div>
        <div>
          <NumberTranslate value={calculations.invoiceTotal} /> {t('units.euro')}
        </div>
      </div>
    </div>
  );
}

function MobileViewDeductionTable({
  calculations,
  householdDeductionRate,
  maxDeduction,
  personCount,
}) {
  const { t } = useTranslation('lang');
  return (
    <div className={`${styles.mobileCalculations}`}>
      <p className={`${styles.calculationsTitle}`}>
        <Translate id="offer.calculations.items" />
      </p>

      {calculations.services.map(({ name, accessory, workshare, total }, index) => (
        <>
          <div className={`${styles.calculationContainer}`} key={name}>
            <div className={`${styles.titleContainer}`}>
              <Translate
                id={
                  ['hardware', 'total'].includes(name.toLowerCase())
                    ? `offer.calculations.${name.toLowerCase()}`
                    : name.charAt(0).toUpperCase() + name.slice(1)
                }
              />
            </div>
            <div className={`${styles.contentContainer}`}>
              <div className={`d-flex ${styles.flexContainer}`}>
                <div className={`${styles.flexTitle}`}>
                  <Translate id="offer.calculations.accessory" />
                </div>
                <div className={`${styles.flexTitle}`}>
                  <Translate id="offer.calculations.work_share" />
                </div>
                <div className={`${styles.flexTitle}`}>
                  <Translate id="offer.calculations.total" />
                </div>
              </div>
              <div className={`d-flex ${styles.flexContainer}`}>
                <div className={`${styles.flexData}`}>
                  <NumberTranslate value={accessory} /> {accessory ? t('units.euro') : ''}
                </div>
                <div className={`${styles.flexData}`}>
                  <NumberTranslate value={workshare} /> {workshare ? t('units.euro') : ''}
                </div>
                <div className={`${styles.flexData}`}>
                  <NumberTranslate value={total} /> {total ? t('units.euro') : ''}
                </div>
              </div>
            </div>
          </div>
          {index + 2 === calculations.services.length && (
            <div className={`d-flex ${styles.flexContainer}`} key={`${name}_blank_line`} />
          )}
        </>
      ))}

      <div className={`${styles.translateContainer}`}>
        <div>
          <Translate id="offer.calculations.reduction" /> {householdDeductionRate}%,
          <Translate id="offer.calculations.max" />
          {`${maxDeduction}${t('units.euro')} ${personCount} `}
          <Translate id="offer.calculations.persons" />
        </div>
        <div>
          -<NumberTranslate value={calculations.reductionValue} />{' '}
          {calculations.reductionValue ? t('units.euro') : ''}
        </div>
      </div>
      <div className={`${styles.translateContainer} ${styles.invoiceTotalContainer}`}>
        <div>
          <Translate id="offer.calculations.invoiceable_total_after_deduction" />
        </div>
        <div>
          <NumberTranslate value={calculations.invoiceTotal} /> {t('units.euro')}
        </div>
      </div>
    </div>
  );
}

function HouseholdDeductionCalculations({
  calculations,
  isUpgradeFromOilHeating,
  onChangeIsUpgradeFromOilHeating,
  householdDeductionRate,
}) {
  // INFO: below 2 values are constant values for now as deduced from the requirements
  const { i18n } = useTranslation('lang');
  const [maxDeduction, setMaxDeduction] = useState(translateNumber(isUpgradeFromOilHeating ? 7000: 3200, i18n.language));
  const personCount = 2;

  useEffect(() => {
    setMaxDeduction(translateNumber(isUpgradeFromOilHeating ? 7000: 3200, i18n.language))
  }, [isUpgradeFromOilHeating, i18n.language])

  const calculation = {
    ...calculations,
  };
  calculation.services = Object.keys(calculations.services).map((k) => {
    const service = calculations.services[k];
    return {
      ...service,
      name: k.substring(0, k.lastIndexOf('_-s-_') > 0 ? k.lastIndexOf('_-s-_') : k.length),
    };
  });
  return (
    <div className={`${styles.houseHoldCalculations}`}>
      <div className={`${styles.checkboxDiv}`}>
        <UpgradeFromOilHeatingInput
          ischecked={isUpgradeFromOilHeating}
          onChange={onChangeIsUpgradeFromOilHeating}
        />
      </div>
      <div className={`${styles.titleDiv}`}>
        <h3 className={`${styles.houseHoldTitle}`}>
          <Translate id="offer.calculations.household_deduction_calculation" />
        </h3>
      </div>
      <div className={`${styles.desktopViewCalculations}`}>
        <DesktopViewDeductionTable
          calculations={calculation}
          householdDeductionRate={householdDeductionRate}
          maxDeduction={maxDeduction}
          personCount={personCount}
        />
      </div>
      <div className={`${styles.mobileViewCalculations}`}>
        <MobileViewDeductionTable
          calculations={calculation}
          householdDeductionRate={householdDeductionRate}
          maxDeduction={maxDeduction}
          personCount={personCount}
        />
      </div>
      <div className={`${styles.costEstimate}`}>
        <Translate id="offer.calculations.cost_estimates_has_not_been_calculated" />
        <sup>*</sup>
      </div>
    </div>
  );
}

HouseholdDeductionCalculations.propTypes = {
  calculations: PropTypes.instanceOf(Object),
  isUpgradeFromOilHeating: PropTypes.bool,
  onChangeIsUpgradeFromOilHeating: PropTypes.func.isRequired,
  householdDeductionRate: PropTypes.number.isRequired,
};

HouseholdDeductionCalculations.defaultProps = {
  calculations: {},
  isUpgradeFromOilHeating: false,
};

DesktopViewDeductionTable.propTypes = {
  calculations: PropTypes.instanceOf(Object),
  householdDeductionRate: PropTypes.number.isRequired,
  maxDeduction: PropTypes.string.isRequired,
  personCount: PropTypes.number.isRequired,
};

DesktopViewDeductionTable.defaultProps = {
  calculations: {},
};

MobileViewDeductionTable.propTypes = {
  calculations: PropTypes.instanceOf(Object),
  householdDeductionRate: PropTypes.number.isRequired,
  maxDeduction: PropTypes.string.isRequired,
  personCount: PropTypes.number.isRequired,
};

MobileViewDeductionTable.defaultProps = {
  calculations: {},
};

UpgradeFromOilHeatingInput.propTypes = {
  ischecked: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default HouseholdDeductionCalculations;
