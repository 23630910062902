/* eslint-disable no-unused-vars */
import { debounce } from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'sonner';

import styles from './index.module.scss';
import Button from '../../../components/button';
import HeatingDeviceCard from '../../../components/device-card';
import HeatingDevicePriceFields from '../../../components/heating-device-price-fields';
import OfferPageContentWrapper from '../../../components/offer-page-content-wrapper';
import ProfitCalculation from '../../../components/profit-calculation';
import SearchBox from '../../../components/search-box';
import Translate from '../../../components/translate';
import { attachmentableTypes } from '../../../constant';
import { uploadAttachments } from '../../../services/attachment';
import HeatingDeviceServices from '../../../services/heatingDevices';
import offerServices from '../../../services/offer';
import { convertBase64ToFile } from '../../../utils/common';
import { getOriginalErrors } from '../../../utils/errorHandler';
import withEnableDisableTab from '../withEnableDisableTab';

const roundToTwoDecimal = (num) => Number(num.toFixed(2));

const calculateAmountInclusiveVat = (amountExclusiveVat, vat) =>
  roundToTwoDecimal(amountExclusiveVat * (1 + vat / 100));

function HeatingDeviceFooter({ onSave, isChanged, onContinue }) {
  const navigate = useNavigate();
  const onBack = () => navigate('../on-site-memo');
  return (
    <div className={`d-flex align-items-center justify-content-end ${styles.btnContainer}`}>
      <div>
        <Button className="projectBtn offerTabFooterBtn offerTabGrayBtn" onClick={onBack}>
          <Translate id="components.button.back" />
        </Button>
      </div>
      <div>
        {isChanged ? (
          <Button className="projectBtn offerTabFooterBtn offerTabGreenBtn" onClick={onSave}>
            <Translate id="components.button.save" />
          </Button>
        ) : (
          <Button className="projectBtn offerTabFooterBtn offerTabGreenBtn" onClick={onContinue}>
            <Translate id="components.button.continue" />
          </Button>
        )}
      </div>
    </div>
  );
}

function HeatingDevice() {
  const [searchTerm, setSearchTerm] = useState('');
  const [heatingDevices, setHeatingDevices] = useState([]);
  const [selectedHeatingDevices, setSelectedHeatingDevices] = useState([]);
  const [selectedHeatingDevicesComparison, setSelectedHeatingDevicesComparison] = useState([]);
  const [offer, setOffer] = useState({});

  const { t } = useTranslation('lang');

  const params = useParams();
  const navigate = useNavigate();

  const onHeatingDeviceClick = async (device) => {
    setHeatingDevices((prevHeatingDevices) =>
      prevHeatingDevices.map((d) => ({
        ...d,
        selected: d.netvisorId === device.netvisorId ? !d.selected : d.selected,
      }))
    );
    const index = selectedHeatingDevices.findIndex((d) => d.netvisorId === device.netvisorId);
    if (index === -1) {
      const deviceToAdd = { ...device };

      delete deviceToAdd.id;

      const { netvisorImageId, netvisorAttachmentIds } = deviceToAdd;
      const queryParams = {};
      if (netvisorImageId && netvisorImageId != null) {
        queryParams.netvisorImageId = netvisorImageId;
      }

      if (netvisorAttachmentIds && netvisorAttachmentIds.length > 0) {
        queryParams.netvisorAttachmentIds = netvisorAttachmentIds.join(',');
      }

      let manufacturerEmail = null;
      if (deviceToAdd.manufacturer) {
        manufacturerEmail = deviceToAdd.manufacturer.email ?? null;
      }

      delete deviceToAdd.manufacturer;

      const {
        data: {
          data: { device: selectedDeviceData },
        },
      } = await HeatingDeviceServices.getHeatingDeviceDetails(deviceToAdd.netvisorId, queryParams);

      const sellingPrice = +selectedDeviceData.sellingPrice.replace('.', '').replace(',', '.');
      const purchasePrice = +selectedDeviceData.purchasePrice.replace('.', '').replace(',', '.');
      const vat = Number(selectedDeviceData.vat.toString().replace(',', '.'));
      const { attachments } = selectedDeviceData;

      setSelectedHeatingDevices((prevHeatingDevices) => [
        ...prevHeatingDevices,
        {
          ...deviceToAdd,
          purchasePrice,
          sellingPrice,
          sellingPriceExclusiveVat: Number(sellingPrice.toFixed(2)),
          sellingPriceInclusiveVat: Number(Number((sellingPrice * (vat + 100)) / 100).toFixed(2)),
          purchasePriceInclusiveVat: Number(Number((purchasePrice * (vat + 100)) / 100).toFixed(2)),
          purchasePriceExclusiveVat: Number(purchasePrice.toFixed(2)),
          quantity: 1,
          vat,
          attachments,
          manufacturerEmail,
          unit: selectedDeviceData.unit,
          netvisorProductCode: selectedDeviceData.netvisorProductCode,
        },
      ]);
    } else {
      setSelectedHeatingDevices((prevHeatingDevices) =>
        prevHeatingDevices.filter((d) => d.netvisorId !== device.netvisorId)
      );
    }
  };

  const onChangeHeatingDeviceQuantity = (heatingDeviceId, quantity) => {
    setSelectedHeatingDevices((prevHeatingDevices) =>
      prevHeatingDevices.map((d) => {
        const device = { ...d };
        if (device.netvisorId === heatingDeviceId) {
          const rate = Number(Number(device.sellingPriceInclusiveVat / device.quantity).toFixed(2));
          device.quantity = quantity;
          device.purchasePriceExclusiveVat = Number(
            Number(device.purchasePrice * quantity).toFixed(2)
          );
          device.purchasePriceInclusiveVat = calculateAmountInclusiveVat(
            device.purchasePriceExclusiveVat,
            device.vat
          );
          if (Number.isNaN(quantity)) {
            device.sellingPriceInclusiveVat = 0;
            device.sellingPriceExclusiveVat = 0;
            device.purchasePriceInclusiveVat = 0;
            device.purchasePriceExclusiveVat = 0;
          } else if (Number.isNaN(rate)) {
            device.sellingPriceExclusiveVat = Number((device.sellingPrice * quantity).toFixed(2));
            device.sellingPriceInclusiveVat = Number(
              Number((device.sellingPriceExclusiveVat * (device.vat + 100)) / 100).toFixed(2)
            );
          } else {
            device.sellingPriceInclusiveVat = Number((rate * quantity).toFixed(2));
            device.sellingPriceExclusiveVat = device.sellingPriceInclusiveVat
              ? Number(
                  Number((device.sellingPriceInclusiveVat * 100) / (device.vat + 100)).toFixed(2)
                )
              : 0;
          }
        }
        return device;
      })
    );
  };

  const onChangeSellingPriceInclusiveVat = (heatingDeviceId, sellingPriceInclusiveVat) => {
    setSelectedHeatingDevices((prevHeatingDevices) =>
      prevHeatingDevices.map((d) => {
        const device = { ...d };
        if (device.netvisorId === heatingDeviceId) {
          device.sellingPriceInclusiveVat = sellingPriceInclusiveVat;

          device.sellingPriceExclusiveVat = Number(
            Number((sellingPriceInclusiveVat * 100) / (device.vat + 100)).toFixed(2)
          );
        }
        return device;
      })
    );
  };

  const fetchOffer = async () => {
    try {
      const {
        data: {
          data: { offer: offerData },
        },
      } = await offerServices.getOfferById(params.offerRequestId, params.offerId);
      setOffer(offerData);
    } catch (err) {
      const originalErrors = getOriginalErrors(err);
      originalErrors.forEach((error) => {
        toast.error(error.message);
      });
    }
  };

  const fetchOfferHeatingDevices = async () => {
    try {
      const {
        data: {
          data: { heatingDevices: offerHeatingDevices },
        },
      } = await HeatingDeviceServices.getOfferHeatingDevices({
        requestId: params.offerRequestId,
        offerId: params.offerId,
      });
      setSelectedHeatingDevices(offerHeatingDevices);
      setSelectedHeatingDevicesComparison(offerHeatingDevices);
    } catch (err) {
      const originalErrors = getOriginalErrors(err);
      originalErrors.forEach((error) => {
        toast.error(error.message);
      });
    }
  };

  const fetchHeatingDevices = async (q) => {
    try {
      const {
        data: {
          data: { devices },
        },
      } = await HeatingDeviceServices.getHeatingDevices({ q });
      setHeatingDevices(
        devices.map((device) => ({
          ...device,
          selected:
            selectedHeatingDevices.findIndex((s) => s.netvisorId === device.netvisorId) !== -1,
        }))
      );
    } catch (error) {
      setHeatingDevices([]);
    }
  };

  useEffect(() => {
    if (searchTerm) {
      fetchHeatingDevices(searchTerm);
    } else {
      setHeatingDevices([]);
    }
  }, [searchTerm]);

  const debouncedSearch = debounce((newValue) => {
    setSearchTerm(newValue);
  }, 1000);

  const onChangeIsHeatingDeviceRequired = (isHeatingDeviceRequired) => {
    setOffer((prev) => ({ ...prev, isHeatingDeviceRequired }));
    setSelectedHeatingDevices([]);
    setHeatingDevices((prev) => [...prev].map((d) => ({ ...d, selected: false })));
  };

  const saveHeatingDevices = async (devices) => {
    const devicesToAdd = devices.map((device) => {
      const { attachments, ...rest } = device;
      return rest;
    });

    const {
      data: {
        data: { heatingDevices: updatedHeatingDevices },
      },
    } = await HeatingDeviceServices.updateOfferHeatingDevices(
      params.offerRequestId,
      params.offerId,
      devicesToAdd
    );

    updatedHeatingDevices.map(async (updatedHeatingDevice) => {
      const device = devices.find((d) => d.netvisorId === updatedHeatingDevice.netvisorId);
      if (device) {
        const { attachments } = device;
        if (Array.isArray(attachments) && attachments.length > 0) {
          const heatingDeviceAttachments = [];

          attachments.forEach((attachment) => {
            const attachmentFile = convertBase64ToFile(
              attachment.$t,
              attachment.name,
              false,
              attachment.mimeType
            );

            heatingDeviceAttachments.push(attachmentFile);
          });

          await uploadAttachments(
            attachmentableTypes.HEATING_DEVICE,
            updatedHeatingDevice.id,
            heatingDeviceAttachments
          );
        }
      }
    });
  };

  const onHeatingDeviceSave = async () => {
    try {
      if (selectedHeatingDevices.length === 0) {
        await HeatingDeviceServices.updateOfferHeatingDevices(
          params.offerRequestId,
          params.offerId,
          []
        );
      } else {
        await saveHeatingDevices(selectedHeatingDevices);
      }

      toast.success(t('offer.heating_device.heating_updated_to_offer'));
      navigate(`/offer-requests/${params.offerRequestId}/offers/${params.offerId}/products`);
    } catch (err) {
      const originalErrors = getOriginalErrors(err);
      originalErrors.forEach((error) => {
        toast.error(error.message);
      });
    }
  };

  const onContinue = async () => {
    navigate(`/offer-requests/${params.offerRequestId}/offers/${params.offerId}/products`);
  };

  const customFooter = () => (
    <HeatingDeviceFooter
      isChanged={
        JSON.stringify(selectedHeatingDevices) !== JSON.stringify(selectedHeatingDevicesComparison)
      }
      onSave={onHeatingDeviceSave}
      onContinue={onContinue}
    />
  );

  useEffect(() => {
    fetchOffer();
    fetchOfferHeatingDevices();
  }, []);

  const onRemove = (netvisorId) => {
    setSelectedHeatingDevices((prev) => prev.filter((d) => d.netvisorId !== netvisorId));
    setHeatingDevices((prevHeatingDevices) =>
      prevHeatingDevices.map((d) => ({
        ...d,
        selected: d.netvisorId === netvisorId ? !d.selected : d.selected,
      }))
    );
  };

  return (
    <OfferPageContentWrapper customFooter={customFooter}>
      <div className={`d-flex flex-column ${styles.heatingDeviceMainContainer}`}>
        <div className={`d-md-flex align-items-center ${styles.headerContainer}`}>
          <div className={`${styles.headerTitle}`}>
            <Translate id="offer.heating_device.choose_heating_device" />
          </div>
          <div>
            <SearchBox
              value={searchTerm}
              onChange={debouncedSearch}
              placeholder="offer.heating_device.search_device"
            />
          </div>
          <div>
            <label className="checkBoxContainer" htmlFor="no_heating_device_required">
              <input
                type="checkbox"
                name="no_heating_device_required"
                id="no_heating_device_required"
                onChange={(e) => onChangeIsHeatingDeviceRequired(!e.target.checked)}
                checked={!offer.isHeatingDeviceRequired}
              />
              <Translate id="offer.heating_device.no_heating_device_required" />
              <span className="checkIcon" />
            </label>
          </div>
        </div>
        <div className={`${styles.cardMainContainer}`}>
          {heatingDevices.length > 0 &&
            heatingDevices.map((device) => (
              <HeatingDeviceCard
                key={device.id}
                name={device.name}
                src={device.image}
                onClick={() => onHeatingDeviceClick(device)}
                disabled={!offer.isHeatingDeviceRequired}
                selected={device.selected}
              />
            ))}
        </div>

        <div className={`${styles.priceFieldMainContainer}`}>
          <h1 className={`${styles.headerTitle}`}>
            <Translate id="offer.heating_device.selected_devices" />
          </h1>
          <div className={`${styles.flexContainer}`}>
            {selectedHeatingDevices.length > 0 &&
              selectedHeatingDevices.map((device, index) => (
                <HeatingDevicePriceFields
                  name={device.name}
                  purchasePriceInclusiveVat={device.purchasePriceInclusiveVat}
                  purchasePriceExclusiveVat={device.purchasePriceExclusiveVat}
                  quantity={device.quantity}
                  onChangeQuantity={onChangeHeatingDeviceQuantity}
                  id={device.netvisorId}
                  sellingPriceInclusiveVat={device.sellingPriceInclusiveVat}
                  sellingPriceExclusiveVat={device.sellingPriceExclusiveVat}
                  vat={device.vat}
                  onChangeSellingPriceInclusiveVat={onChangeSellingPriceInclusiveVat}
                  onRemove={onRemove}
                />
              ))}
          </div>
        </div>
        <div className={`${styles.profitCalculation}`}>
          <ProfitCalculation
            items={selectedHeatingDevices}
            purchasePriceFieldName="purchasePriceExclusiveVat"
            sellingPriceFieldName="sellingPriceExclusiveVat"
          />
        </div>
      </div>
    </OfferPageContentWrapper>
  );
}

HeatingDeviceFooter.propTypes = {
  onSave: PropTypes.func.isRequired,
};

export default withEnableDisableTab(HeatingDevice);
