import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import CurrencyInput from 'react-currency-input-field';
import { useTranslation } from 'react-i18next';

import styles from './index.module.scss';
import { ReactComponent as TrashSvg } from '../../assets/svgs/trash.svg';
import NumberControl from '../form-fields/number-control';
import NumberTranslate from '../number-translate';
import Translate from '../translate';

function ServicesNumberControl({ name, unit, value, onChange, exclusiveVat, readOnly }) {
  const { t, i18n } = useTranslation('lang');
  const [price, setPrice] = useState(value);
  const priceChange = (number) => {
    setPrice(number);
    if (i18n.language !== 'en') {
      onChange(number ? +number.toString().replace(',', '.') : 0);
    } else {
      onChange(+number || 0);
    }
  };

  useEffect(() => {
    if (value !== Number(price)) {
      setPrice(value);
    }
  }, [value]);

  const getNumberFormat = (language) => {
    switch (language) {
      case 'en':
        return {
          groupSeparator: ',',
          decimalSeparator: '.',
        };
      case 'fi':
        return {
          groupSeparator: '.',
          decimalSeparator: ',',
        };
      default:
        return {
          groupSeparator: ',',
          decimalSeparator: '.',
        };
    }
  };

  const { groupSeparator, decimalSeparator } = getNumberFormat(i18n.language);

  return (
    <div className={`d-flex justify-content-between ${styles.numberInputLabelContainer}`}>
      <div className={`${styles.exclusiveVatContainer}`}>
        <div className="text-break">
          <NumberTranslate value={exclusiveVat} />{' '}
          {t('units.euro')}
        </div>
      </div>
      <div className={`d-flex align-items-center ${styles.flexChildInputContainer}`}>
        <div>
          <CurrencyInput
            name={name}
            groupSeparator={groupSeparator}
            decimalSeparator={decimalSeparator}
            decimalScale={2}
            onValueChange={(amount) => {
              priceChange(amount);
            }}
            value={price}
            className={`inputField ${styles.inputField}`}
            readOnly={readOnly}
          />
          {/* <input
            className={`inputField ${styles.inputField}`}
            name={name}
            type="number"
            value={value.toString()}
            onChange={(e) => onChange(e.target.valueAsNumber)}
          /> */}
        </div>
        <div>
          <p className={`${styles.centimeter}`}>
            {unit && unit instanceof Object ? unit : t(unit)}
          </p>
        </div>
      </div>
    </div>
  );
}

function QuantityInput({ quantity, setQuantity, rateExclusiveVat, rate, unit, onChangePrice }) {
  const { t, i18n } = useTranslation('lang');
  const [price, setPrice] = useState(rate);
  const priceChange = (number) => {
    setPrice(number);
    if (i18n.language !== 'en') {
      onChangePrice(number ? +number.toString().replace(',', '.') : 0);
    } else {
      onChangePrice(+number || 0);
    }
  };
  return (
    <div className={`d-sm-flex align-items-sm-center  ${styles.quantityContainer}`}>
      <div className="d-flex align-items-center justify-content-between">
        <div>{t('offer.services.quantity')}</div>

        <div>
          <NumberControl name="quantity" value={quantity} onChange={setQuantity} unit={unit} />
        </div>
      </div>
      <div className="d-flex align-items-center justify-content-between">
        <div className={`d-flex align-items-center  ${styles.innerColumnContainer}`}>
          <div>
            <NumberTranslate value={rateExclusiveVat} />
          </div>
          <div>{t('units.euro')}</div>
        </div>
        <div>
          <NumberControl
            name="amount_price"
            unit={`${t('units.euro')}/${unit}`}
            value={price}
            onChange={priceChange}
            isPrice
          />
        </div>
      </div>
    </div>
  );
}

function PurchasePrice({
  totalPriceInclusiveVat,
  totalPriceExclusiveVat,
  setTotalPurchasePriceInclVat,
}) {
  return (
    <div
      className={`d-flex flex-wrap align-items-center justify-content-between ${styles.flexChild}`}
    >
      <div>
        <Translate id="offer.products.purchase_price" />
      </div>
      <ServicesNumberControl
        name="purchase_price"
        unit="units.euro"
        value={totalPriceInclusiveVat}
        onChange={setTotalPurchasePriceInclVat}
        exclusiveVat={totalPriceExclusiveVat}
        readOnly
      />
    </div>
  );
}

function SellingPrice({
  totalPriceInclusiveVat,
  totalPriceExclusiveVat,
  setTotalSellingPriceInclusiveVat,
}) {
  return (
    <div
      className={`d-flex flex-wrap align-items-center justify-content-between ${styles.flexChild}`}
    >
      <div>
        <Translate id="offer.services.selling_price" />
      </div>
      <div>
        <ServicesNumberControl
          name="selling_price"
          unit="units.euro"
          value={totalPriceInclusiveVat}
          onChange={setTotalSellingPriceInclusiveVat}
          exclusiveVat={totalPriceExclusiveVat}
        />
      </div>
    </div>
  );
}

function PriceColumnHeader({ vat }) {
  const { t } = useTranslation('lang');

  return (
    <div className={`${styles.vatColumnContainer}`}>
      <div>{/* <p>blank div</p> */}</div>
      <div className="d-flex align-items-center">
        <div>
          <Translate id="offer.services.exclusive_vat" />
        </div>
        <div>{t('offer.products.inclusive_vat', { vatPercentage: vat })}</div>
      </div>
    </div>
  );
}

function WorkShareInput({ value, setWorkshare }) {
  return (
    <div className={`${styles.workShareColumn}`}>
      <NumberControl
        name="work_share"
        value={value}
        label="offer.services.work_share"
        unit="offer.services.unit_percentage"
        onChange={setWorkshare}
      />
    </div>
  );
}

function RemoveButton({ onClick }) {
  const { t } = useTranslation('lang');
  return (
    <button
      className={`d-flex align-items-center projectBtn ${styles.removeButton}`}
      type="button"
      onClick={onClick}
    >
      <div>
        <TrashSvg />
      </div>
      <div>{t('components.button.remove')}</div>
    </button>
  );
}

function ServicePricing({
  id,
  name,
  quantity,
  totalPurchasePriceInclusiveVat,
  totalPurchasePriceExclusiveVat,
  totalSellingPriceInclusiveVat,
  totalSellingPriceExclusiveVat,
  setTotalSellingPriceInclusiveVat,
  setTotalPurchasePriceInclVat,
  workshare,
  setWorkshare,
  setQuantity,
  onRemove,
  vat,
  rateExclusiveVat,
  rateInclusiveVat,
  unit,
  onChangeServicePrice,
}) {
  return (
    <div className={`${styles.servicePriceContainer}`}>
      <div className={`d-flex ${styles.serviceNameContainer}`}>
        <div>
          <p>
            <Translate id="offer.services.id" /> : {id} - &nbsp;
          </p>
        </div>
        <div className={`${styles.serviceName}`}>{name}</div>
        <div className={`${styles.btnContainer}`}>
          <RemoveButton onClick={onRemove} />
        </div>
      </div>
      <div className={`${styles.serviceDetailsContainer}`}>
        <PriceColumnHeader vat={vat} />
        <QuantityInput
          quantity={quantity}
          setQuantity={setQuantity}
          rateExclusiveVat={rateExclusiveVat}
          rate={rateInclusiveVat}
          unit={unit}
          onChangePrice={(value) => onChangeServicePrice(value < 0 ? 0 : value)}
        />
        <PriceColumnHeader vat={vat} />
        <PurchasePrice
          totalPriceExclusiveVat={totalPurchasePriceExclusiveVat}
          totalPriceInclusiveVat={totalPurchasePriceInclusiveVat}
          setTotalPurchasePriceInclVat={setTotalPurchasePriceInclVat}
        />
        <SellingPrice
          totalPriceExclusiveVat={totalSellingPriceExclusiveVat}
          totalPriceInclusiveVat={totalSellingPriceInclusiveVat}
          setTotalSellingPriceInclusiveVat={setTotalSellingPriceInclusiveVat}
        />
        <WorkShareInput value={workshare} setWorkshare={setWorkshare} />
      </div>
    </div>
  );
}

ServicePricing.propTypes = {
  name: PropTypes.string.isRequired,
  quantity: PropTypes.number.isRequired,
  totalSellingPriceInclusiveVat: PropTypes.number.isRequired,
  totalSellingPriceExclusiveVat: PropTypes.number.isRequired,
  totalPurchasePriceInclusiveVat: PropTypes.number.isRequired,
  totalPurchasePriceExclusiveVat: PropTypes.number.isRequired,
  setTotalSellingPriceInclusiveVat: PropTypes.func.isRequired,
  setTotalPurchasePriceInclVat: PropTypes.func.isRequired,
  workshare: PropTypes.number.isRequired,
  setWorkshare: PropTypes.func.isRequired,
  setQuantity: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
};

PurchasePrice.propTypes = {
  totalPriceInclusiveVat: PropTypes.number.isRequired,
  totalPriceExclusiveVat: PropTypes.number.isRequired,
  setTotalPurchasePriceInclVat: PropTypes.func.isRequired,
};

SellingPrice.propTypes = {
  totalPriceInclusiveVat: PropTypes.number.isRequired,
  totalPriceExclusiveVat: PropTypes.number.isRequired,
  setTotalSellingPriceInclusiveVat: PropTypes.func.isRequired,
};

WorkShareInput.propTypes = {
  value: PropTypes.number.isRequired,
  setWorkshare: PropTypes.func.isRequired,
};

QuantityInput.propTypes = {
  quantity: PropTypes.number.isRequired,
  setQuantity: PropTypes.func.isRequired,
  rateExclusiveVat: PropTypes.number.isRequired,
  rate: PropTypes.number.isRequired,
  unit: PropTypes.string.isRequired,
  onChangePrice: PropTypes.func.isRequired,
};

RemoveButton.propTypes = {
  onClick: PropTypes.func.isRequired,
};

ServicesNumberControl.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  unit: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  exclusiveVat: PropTypes.string.isRequired,
  readOnly: PropTypes.bool,
};

ServicesNumberControl.defaultProps = {
  unit: null,
  readOnly: false,
};
export default ServicePricing;
